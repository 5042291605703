import * as Types from '../types';

export type OrgFragment = (
  Pick<Types.Org, 'logoUrl' | 'name' | 'trialDuration' | 'type' | 'zipCode' | 'id' | 'code' | 'primaryExternalId' | 'activationCode'>
  & { parent?: (
    Pick<Types.Org, 'id' | 'name'>
    & { parent?: Pick<Types.Org, 'id' | 'name'> }
  ), configuration?: Pick<Types.OrgConfiguration, 'autoAcceptLisaChatResponse'> }
);

export type MemberAttributeFragment = (
  Pick<Types.MemberAttribute, 'id' | 'identifiedBy' | 'memberId' | 'updatedAt'>
  & { attribute: Pick<Types.AttributeOption, 'category' | 'key' | 'value' | 'description'>, barriers?: Array<ConnectedBarrierFragment> }
);

export type AdmissionCoachFragment = (
  Pick<Types.Admission, 'id' | 'admitDate' | 'admitType' | 'admitSource' | 'dischargeDate' | 'dischargeTo' | 'facility' | 'specialInstructions' | 'reasonForAdmission' | 'hospitalCourse' | 'admissionSummary' | 'progressSummary' | 'transitionSummary' | 'drg' | 'drgDesc' | 'warningSigns' | 'createdAt' | 'updatedAt' | 'published' | 'memberReportedInformation' | 'warningSignsNotes' | 'woundCareNotes' | 'nurseNotes' | 'requestId' | 'caseOwnerName'>
  & { activity?: Pick<Types.Activity, 'general' | 'lifting' | 'showerOrBathing' | 'stairs' | 'driving' | 'sexualActivity' | 'work'>, woundCare?: Pick<Types.WoundCare, 'general'>, diagnoses?: Array<Pick<Types.Diagnosis, 'id' | 'code' | 'description' | 'primaryType' | 'secondaryType' | 'clinicalStatus' | 'severity' | 'onsetStart' | 'onsetEnd'>>, treatmentRendereds?: Array<Pick<Types.TreatmentRendered, 'id' | 'code' | 'startDate' | 'endDate'>>, medications?: Array<MedicationFragmentWithBarriersFragment>, externalAppointments?: Array<ExternalAppointmentFragmentWithBarriersFragment>, dietaries?: Array<Pick<Types.Dietary, 'id' | 'category' | 'name' | 'date' | 'notes'>>, files?: Array<Pick<Types.AdmissionFile, 'id' | 'admissionFileType' | 'fileName' | 'isVisibleToMember' | 'uploadedBy'>>, externalNotes?: Pick<Types.ExternalNotes, 'caseType' | 'cmDcPlan' | 'primaryDx' | 'product' | 'specialInstructions' | 'umClinical' | 'umDcNote'> }
);

export type JourneyFragment = (
  Pick<Types.Journey, 'id' | 'memberId' | 'readmissionRisk' | 'isGraduated' | 'graduationDate' | 'generalNotes' | 'status' | 'statusNote' | 'statusReason'>
  & { readmissionRiskHistory?: Array<Pick<Types.ReadmissionRiskHistory, 'readmissionRisk' | 'date'>> }
);

export type BarrierFragment = (
  Pick<Types.Barrier, 'memberId' | 'createdBy' | 'createdAt' | 'updatedAt' | 'notes' | 'id' | 'identifiedBy' | 'completedAt' | 'status'>
  & { type: Pick<Types.BarrierType, 'description' | 'id' | 'engage' | 'influence' | 'support' | 'type' | 'domain'>, context?: Pick<Types.BarrierContext, 'metadata' | 'resourceId' | 'parentResourceId' | 'type'> }
);

export type ConnectedBarrierFragment = (
  Pick<Types.Barrier, 'id' | 'status'>
  & { type: Pick<Types.BarrierType, 'description' | 'id'> }
);

export type AdmissionOverviewFragment = Pick<Types.AdmissionOverview, 'id' | 'admissionId' | 'memberId' | 'overviewStatus' | 'text'>;

export type MedicationFragmentWithBarriersFragment = (
  Pick<Types.Medication, 'id' | 'status' | 'name' | 'rxNorm' | 'route' | 'dosage' | 'specialInstructions' | 'cronExpressions' | 'start' | 'end' | 'memberVerballyProvided'>
  & { barriers?: Array<ConnectedBarrierFragment> }
);

export type ExternalAppointmentFragmentWithBarriersFragment = (
  Pick<Types.ExternalAppointment, 'id' | 'status' | 'placeOfService' | 'drName' | 'clinic' | 'type' | 'specialInstructions' | 'fullAddress' | 'phone' | 'fax' | 'cronExpressions' | 'start' | 'end'>
  & { barriers?: Array<ConnectedBarrierFragment> }
);

export type MemberEngineActionFragment = (
  Pick<Types.EngineAction, 'id' | 'title' | 'subTitle' | 'status' | 'payload' | 'type' | 'memberId' | 'createdAt' | 'notes'>
  & { sources?: Array<Pick<Types.Source, 'type' | 'subType' | 'resourceId' | 'parentResourceId' | 'metadata'>> }
);

export type ChatFragment = (
  Pick<Types.Chat, 'clientId' | 'memberId' | 'sendBirdChannelUrl' | 'coverUrl' | 'channelName' | 'channelType' | 'timezone' | 'primaryUserId' | 'isPushNotificationsEnabled' | 'unreadMessageCount' | 'relationship' | 'memberName'>
  & { channelLastMessage?: Pick<Types.ChatMessage, 'message' | 'timestamp'> }
);

export type PromptResponseFragment = (
  Pick<Types.ChatCompletion, 'id' | 'prompt' | 'promptKey' | 'promptVersion' | 'model' | 'modelVersion' | 'promptText' | 'promptData' | 'jobId' | 'processTime' | 'isInvalid' | 'memberId' | 'recordingId' | 'transcriptParagraphId' | 'sendBirdChannelUrl' | 'response' | 'createdAt' | 'createdBy'>
  & { failures?: Array<Pick<Types.Failure, 'message' | 'stack'>>, tokens?: Pick<Types.ChatCompletionTokens, 'completion' | 'total' | 'prompt'> }
);

export type PromptFragment = (
  Pick<Types.Prompt, 'id' | 'key' | 'template' | 'version' | 'active' | 'isObsolete' | 'abstractModel'>
  & { options?: Pick<Types.ChatCompletionsOptions, 'topP' | 'temperature' | 'maxTokens'> }
);

export type PromptSimulationJobFragment = (
  Pick<Types.PromptSimulationJob, 'id' | 'start' | 'end' | 'status' | 'error' | 'promptId' | 'abstractModel'>
  & { chatCompletionsOptions?: Pick<Types.ChatCompletionsOptions, 'maxTokens' | 'temperature' | 'topP'>, context: Pick<Types.ChatCompletionContext, 'memberId' | 'recordingId' | 'transcriptParagraphId' | 'sendBirdChannelUrl' | 'jobId'> }
);

export type BaseRecordingInsightFragment = Pick<Types.RecordingInsight, 'id' | 'duration' | 'orgId' | 'userId' | 'memberId' | 'allianceSummary' | 'allianceTotalScore'>;

export type MemberTraitFragment = (
  Pick<Types.TraitSchemaForMemberResponse, 'id' | 'active' | 'name' | 'domain' | 'definition' | 'displayType'>
  & { values: Array<Pick<Types.TraitValue, 'value' | 'potentialIssue' | 'associatedBarriers'>>, memberTrait?: (
    Pick<Types.TraitResponse, 'id' | 'identifiedBy' | 'reasoning' | 'values' | 'updatedAt'>
    & { context?: Pick<Types.TraitContext, 'recordingId'> }
  ) }
);

export type RecordingFragment = (
  Pick<Types.Recording, 'id' | 'memberId' | 'candidateId' | 'userId' | 'appointmentId' | 'start' | 'end' | 'recordingType' | 'status'>
  & { review?: Pick<Types.RecordingReview, 'userId' | 'content' | 'createdAt' | 'updatedAt'>, survey?: (
    Pick<Types.QuestionnaireResponse, 'id'>
    & { answers: Array<Pick<Types.Answer, 'code' | 'value'>> }
  ), participants: Array<Pick<Types.Participant, 'id' | 'start' | 'end' | 'phone' | 'answered' | 'consent' | 'identityVerification'>>, recordingTranscriptSummary?: (
    Pick<Types.RecordingTranscriptSummaryResponse, 'summary' | 'outreachSummary' | 'memberPrioritizedGoalAndProgress' | 'selfManagementPlan' | 'followUp' | 'status'>
    & { domains?: Array<Pick<Types.DomainEntry, 'type' | 'data'>> }
  ), recordingTranscriptInsights?: (
    Pick<Types.RecordingTranscriptInsightsResponse, 'status'>
    & { memberSentiment?: Pick<Types.Sentiment, 'score' | 'explanation'>, qualities?: Array<Pick<Types.Quality, 'type' | 'value' | 'title' | 'text'>> }
  ), callPreparation?: Array<Pick<Types.CallPreparation, 'id' | 'identifiedBy' | 'memberId' | 'recordingId' | 'text'>> }
);

export type CallPreparationFragment = Pick<Types.CallPreparation, 'id' | 'identifiedBy' | 'memberId' | 'recordingId' | 'text'>;

export type ScoreRankingFragment = Pick<Types.ScoreRanking, 'label' | 'scoreCount'>;

export type ScoreRankingOrgInsightsFragment = (
  Pick<Types.ScoreRankingOrgInsights, 'orgId'>
  & { scoreRankings: Array<ScoreRankingFragment> }
);

export const OrgFragmentDoc = `
    fragment orgFragment on Org {
  logoUrl
  name
  trialDuration
  type
  zipCode
  id
  code
  primaryExternalId
  parent {
    id
    name
    parent {
      id
      name
    }
  }
  configuration {
    autoAcceptLisaChatResponse
  }
  activationCode
}
    `;
export const ConnectedBarrierFragmentDoc = `
    fragment connectedBarrierFragment on Barrier {
  type {
    description
    id
  }
  id
  status
}
    `;
export const MemberAttributeFragmentDoc = `
    fragment memberAttributeFragment on MemberAttribute {
  attribute {
    category
    key
    value
    description
  }
  id
  identifiedBy
  memberId
  updatedAt
  barriers {
    ...connectedBarrierFragment
  }
}
    `;
export const MedicationFragmentWithBarriersFragmentDoc = `
    fragment medicationFragmentWithBarriers on Medication {
  id
  status
  name
  rxNorm
  route
  dosage
  specialInstructions
  cronExpressions
  start
  end
  memberVerballyProvided
  barriers {
    ...connectedBarrierFragment
  }
}
    `;
export const ExternalAppointmentFragmentWithBarriersFragmentDoc = `
    fragment externalAppointmentFragmentWithBarriers on ExternalAppointment {
  id
  status
  placeOfService
  drName
  clinic
  type
  specialInstructions
  fullAddress
  phone
  fax
  cronExpressions
  start
  end
  barriers {
    ...connectedBarrierFragment
  }
}
    `;
export const AdmissionCoachFragmentDoc = `
    fragment admissionCoachFragment on Admission {
  id
  admitDate
  admitType
  admitSource
  dischargeDate
  dischargeTo
  facility
  specialInstructions
  reasonForAdmission
  hospitalCourse
  admissionSummary
  progressSummary
  transitionSummary
  drg
  drgDesc
  warningSigns
  createdAt
  updatedAt
  published
  memberReportedInformation
  activity {
    general
    lifting
    showerOrBathing
    stairs
    driving
    sexualActivity
    work
  }
  woundCare {
    general
  }
  warningSignsNotes
  woundCareNotes
  diagnoses {
    id
    code
    description
    primaryType
    secondaryType
    clinicalStatus
    severity
    onsetStart
    onsetEnd
  }
  treatmentRendereds {
    id
    code
    startDate
    endDate
  }
  medications {
    ...medicationFragmentWithBarriers
  }
  externalAppointments {
    ...externalAppointmentFragmentWithBarriers
  }
  dietaries {
    id
    category
    name
    date
    notes
  }
  nurseNotes
  files {
    id
    admissionFileType
    fileName
    isVisibleToMember
    uploadedBy
  }
  externalNotes {
    caseType
    cmDcPlan
    primaryDx
    product
    specialInstructions
    umClinical
    umDcNote
  }
  requestId
  caseOwnerName
}
    `;
export const JourneyFragmentDoc = `
    fragment journeyFragment on Journey {
  id
  memberId
  readmissionRisk
  readmissionRiskHistory {
    readmissionRisk
    date
  }
  isGraduated
  graduationDate
  generalNotes
  status
  statusNote
  statusReason
}
    `;
export const BarrierFragmentDoc = `
    fragment barrierFragment on Barrier {
  memberId
  type {
    description
    id
    engage
    influence
    support
    type
    domain
  }
  createdBy
  createdAt
  updatedAt
  notes
  id
  identifiedBy
  completedAt
  status
  context {
    metadata
    resourceId
    parentResourceId
    type
  }
}
    `;
export const AdmissionOverviewFragmentDoc = `
    fragment admissionOverviewFragment on AdmissionOverview {
  id
  admissionId
  memberId
  overviewStatus
  text
}
    `;
export const MemberEngineActionFragmentDoc = `
    fragment memberEngineActionFragment on EngineAction {
  id
  title
  subTitle
  status
  payload
  type
  memberId
  createdAt
  notes
  sources {
    type
    subType
    resourceId
    parentResourceId
    metadata
  }
}
    `;
export const ChatFragmentDoc = `
    fragment chatFragment on Chat {
  clientId
  memberId
  sendBirdChannelUrl
  coverUrl
  channelName
  channelType
  timezone
  primaryUserId
  isPushNotificationsEnabled
  unreadMessageCount
  channelLastMessage {
    message
    timestamp
  }
  relationship
  memberName
}
    `;
export const PromptResponseFragmentDoc = `
    fragment promptResponseFragment on ChatCompletion {
  id
  prompt
  promptKey
  promptVersion
  model
  modelVersion
  promptText
  promptData
  jobId
  processTime
  isInvalid
  memberId
  recordingId
  transcriptParagraphId
  sendBirdChannelUrl
  response
  createdAt
  createdBy
  failures {
    message
    stack
  }
  tokens {
    completion
    total
    prompt
  }
}
    `;
export const PromptFragmentDoc = `
    fragment promptFragment on Prompt {
  id
  key
  template
  version
  active
  isObsolete
  abstractModel
  options {
    topP
    temperature
    maxTokens
  }
}
    `;
export const PromptSimulationJobFragmentDoc = `
    fragment promptSimulationJobFragment on PromptSimulationJob {
  id
  start
  end
  status
  error
  promptId
  abstractModel
  chatCompletionsOptions {
    maxTokens
    temperature
    topP
  }
  context {
    memberId
    recordingId
    transcriptParagraphId
    sendBirdChannelUrl
    jobId
  }
}
    `;
export const BaseRecordingInsightFragmentDoc = `
    fragment baseRecordingInsight on RecordingInsight {
  id
  duration
  orgId
  userId
  memberId
  allianceSummary
  allianceTotalScore
}
    `;
export const MemberTraitFragmentDoc = `
    fragment memberTraitFragment on TraitSchemaForMemberResponse {
  id
  active
  name
  domain
  definition
  values {
    value
    potentialIssue
    associatedBarriers
  }
  displayType
  memberTrait {
    id
    identifiedBy
    reasoning
    values
    updatedAt
    context {
      recordingId
    }
  }
}
    `;
export const RecordingFragmentDoc = `
    fragment recordingFragment on Recording {
  id
  memberId
  candidateId
  userId
  appointmentId
  start
  end
  recordingType
  review {
    userId
    content
    createdAt
    updatedAt
  }
  survey {
    id
    answers {
      code
      value
    }
  }
  participants {
    id
    start
    end
    phone
    answered
    consent
    identityVerification
  }
  status
  recordingTranscriptSummary {
    summary
    outreachSummary
    memberPrioritizedGoalAndProgress
    selfManagementPlan
    followUp
    status
    domains {
      type
      data
    }
  }
  recordingTranscriptInsights {
    memberSentiment {
      score
      explanation
    }
    qualities {
      type
      value
      title
      text
    }
    status
  }
  callPreparation {
    id
    identifiedBy
    memberId
    recordingId
    text
  }
}
    `;
export const CallPreparationFragmentDoc = `
    fragment callPreparationFragment on CallPreparation {
  id
  identifiedBy
  memberId
  recordingId
  text
}
    `;
export const ScoreRankingFragmentDoc = `
    fragment ScoreRankingFragment on ScoreRanking {
  label
  scoreCount
}
    `;
export const ScoreRankingOrgInsightsFragmentDoc = `
    fragment ScoreRankingOrgInsightsFragment on ScoreRankingOrgInsights {
  orgId
  scoreRankings {
    ...ScoreRankingFragment
  }
}
    `;