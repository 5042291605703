import { v4 } from '@laguna/common/utils/general';

const createChannel = () => new BroadcastChannel(window.location.host);
const channel = createChannel();

export const currentTabId = v4();
export enum TabManagerEvents {
  ChangeEnv = 'ChangeEnv',
  Logout = 'Logout',
  Login = 'Login',
  RequestURLFromOtherTabs = 'RequestURLFromOtherTabs',
  SendURLToRequester = 'SendURLToRequester',
}

interface GenericMessage {
  payload: {
    type: TabManagerEvents.Logout | TabManagerEvents.Login | TabManagerEvents.RequestURLFromOtherTabs;
    id: string;
  };
}

interface ChangeTabEvent {
  payload: { type: TabManagerEvents.ChangeEnv; id: string; name: string };
}
interface SendURLToRequesterEvent {
  payload: { type: TabManagerEvents.SendURLToRequester; id: string; url: string; requesterId: string };
}
const MESSAGE = 'message';

// when called, send ping, and listen for any 'pongs' getting back from external tabs

export const sendMessage = (type: TabManagerEvents, extraPayload: any = {}) =>
  channel.postMessage({ payload: { id: currentTabId, type, ...extraPayload } });

export const logoutTabSync = () => sendMessage(TabManagerEvents.Logout);
export const loginTabSync = () => sendMessage(TabManagerEvents.Login);

export type TabMessages = GenericMessage | ChangeTabEvent | SendURLToRequesterEvent;

channel.addEventListener(
  MESSAGE,
  ({ data }: MessageEvent<GenericMessage | ChangeTabEvent | SendURLToRequesterEvent>) => {
    const { payload } = data;
    if (payload?.type) {
      switch (payload.type) {
        case TabManagerEvents.Logout:
        case TabManagerEvents.Login: {
          return window.location.reload();
        }
      }
    }
  }
);
