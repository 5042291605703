import { RelaySocketMessage } from '@laguna/api/socketsUtilsTypes';

export enum PATHS_LITE {
  UNIDENTIFIED_CALL = 'unidentified-call',
}
export enum MEMBER_TABS_LITE {
  HOME = 'home',
  MEMBER_INFO = 'home',
  CALLS = 'sessions',
  SESSIONS = 'sessions',
  MESSAGES = 'messages',
  BARRIERS = 'barriers',
  PATHWAYS = 'barriers',
  PROFILE = 'profile',
  LIVE_CALL = 'live-call',
  CALL_PREP = 'prep',
}
export type Activation = {
  baseUrl: string;
  code: string;
  orgName: string;
};

export type ExtensionState = {
  credentials?: { origin: string; credentialsInfo: Record<string, string> };
  activation?: Activation;
};

type ExtensionActiveState = 'ready' | 'active' | 'inactive';
type PartialEvent = { type: 'partial'; text: string };
type UtteranceEvent = { type: 'utterance'; text: string };
type StateChangeEvent = { type: 'stateChange'; state: ExtensionActiveState };
type ToggleView = { type: 'toggleView'; value: boolean };
type ToggleFabOpenState = { type: 'toggleFabOpenState'; value: boolean };
type ChangeBackground = { type: 'changeBackground'; value: boolean };
type ExternalNavigation = { type: 'externalNavigation'; location: { memberId?: string } };
type Initialized = { type: 'initialized' };
type GetState = { type: 'getState' };
type SetViewState = { type: 'setViewState'; state: 'visible' | 'hidden' };
type SetCredentials = { type: 'setCredentials'; origin: string; credentialsInfo: Record<string, string> };
type ClearCredentials = { type: 'clearCredentials' };
type RelayMessage = { type: 'relayMessage'; value: RelaySocketMessage };
type ActivateLagunaNow = { type: 'activateLagunaNow'; activation: Activation };
type DeactivateLagunaNow = { type: 'deactivateLagunaNow' };
type NewAlert = { type: 'newAlert' };
type AlertsSeen = { type: 'alertsSeen' };
type ShowSendMemberInfoButton = { type: 'showSendMemberInfoButton' };
type OnSendMemberInfo = { type: 'onSendMemberInfo'; info: { memberId: string } };

export const LAGUNA_NOW_EVENT = 'laguna_now';

export type ExtensionMessageType =
  | PartialEvent
  | UtteranceEvent
  | StateChangeEvent
  | ToggleView
  | ChangeBackground
  | ExternalNavigation
  | GetState
  | SetViewState
  | Initialized
  | ToggleFabOpenState
  | SetCredentials
  | ClearCredentials
  | RelayMessage
  | ActivateLagunaNow
  | DeactivateLagunaNow
  | NewAlert
  | AlertsSeen
  | ShowSendMemberInfoButton
  | OnSendMemberInfo;
